import React from "react";
import {graphql} from "gatsby";
import CategoryLayout from "../../components/tronpedia/category-layout";
import {Helmet} from "react-helmet";
import defiBull from "../../images/tronpedia/topic-pages/defi-bull.png";

const Defi = ({data, location}) => {

    const pageData = {
        title: "DEFI",
        description: "So… I can bank without a bank? Check out our articles to learn about how trust-free finance works and some of the biggest protocols in the space now.",
        img: defiBull,
    }


    return (
        <>
            <Helmet>
                <title>TRONpedia Decentralized Finance DEFI | TRONDAO</title>
                <meta name="description"
                      content="Trying to learn everything there is to know about DeFi? Look no further! TRONPedia has you covered with articles on all sorts of topics."/>
                <meta property="og:description"
                      content="Trying to learn everything there is to know about DeFi? Look no further! TRONPedia has you covered with articles on all sorts of topics."/>
                <meta property="og:title" content="TRONpedia Decentralized Finance DEFI | TRONDAO"/>
            </Helmet>
            <CategoryLayout data={data} location={location} pageData={pageData}/>
        </>
    );
};


export default Defi;

export const pageQuery = graphql`
query {
   allWpTronpediaArticle (
    filter: {tronpedia_article_info: {category: {eq: "DEFI"}}}
  ) {  
      edges {
         node {
            slug
        title
        tronpedia_article_info {
           category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
   }
}

`
